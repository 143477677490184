/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "De una forma paralela al ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2013/01/26/product-lifecycle-management/"
  }, "PLM"), " se ha desarrollado otro tipo de herramienta integrada pero esta vez orientada al edificación. La idea es muy similar, integrar todos los datos pertenecientes al ciclo de vida de un edificio, por lo tanto gran parte de lo que vimos para PLM sirve a para BIM, pero además en este caso contamos con varias ventajas en lo que nos interesa aquí, que es el mundo del software libre."), "\n", React.createElement(_components.p, null, "En primer lugar el BIM cuenta con un estándar libre y claro el ", React.createElement(_components.a, {
    href: "http://www.bimstop.com/blog/ifc-bim-explained/"
  }, "IFC,"), " que define como construir los archivos que contienen la base de datos del edificio, esto ha permitido que se construyan aplicaciones BIM sobre este estándar y que sean interoperables entre ellas o incluso con aplicaciones comerciales cerradas."), "\n", React.createElement(_components.p, null, "Por lo tanto encontramos una gran variedad de herramientas relacionadas con BIM de software libre, como ", React.createElement(_components.a, {
    href: "http://bimserver.org/about/"
  }, "servidores para almacenar la información de forma remota y centralizada"), ", ", React.createElement(_components.a, {
    href: "http://www.openbim.org/"
  }, "middleware para crear nuevas aplicaciones BIM"), ", y ", React.createElement(_components.a, {
    href: "http://www.openifctools.org/Open_IFC_Tools/Home.html"
  }, "herramientas BIM"), " que desgraciadamente llevan licencia no comercial, cosa que me parece un sin sentido."), "\n", React.createElement(_components.p, null, "También podemos encontrar herramientas cerradas pero gratuitas a continuación os dejo ", React.createElement(_components.a, {
    href: "http://www.bitacoravirtual.cl/2010/08/27/open-ifc-tools-herramientas-open-source-para-proyectos-bim/"
  }, "una buena compilación de todas ellas"), " hecha por un buen blog de arquitectura y software libre."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
